var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-main",
    { staticClass: "trade-background" },
    [
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { staticClass: "mt-12" },
            [
              _c("v-col", { attrs: { cols: "1", sm: "1", md: "1" } }),
              _c(
                "v-col",
                [
                  _c(
                    "v-row",
                    { attrs: { align: "center" } },
                    [
                      _c("v-col", { attrs: { cols: "2", md: "2" } }, [
                        _c(
                          "a",
                          {
                            staticClass: "black--text",
                            attrs: {
                              href: "https://tradeadvisory.co.za/",
                              target: "_blank"
                            }
                          },
                          [
                            _c("img", {
                              staticClass: "ml-md-0 login_img",
                              attrs: {
                                src:
                                  "https://tradeadvisory.co.za/wp-content/uploads/2023/01/TA_Orange_Grey-300x160.png",
                                alt: "trade advisory company logo"
                              }
                            })
                          ]
                        )
                      ]),
                      _c("v-col", { attrs: { cols: "9", md: "9" } }),
                      _c(
                        "v-col",
                        {
                          staticClass: "top-line mt-6",
                          attrs: { cols: "12", lg: "4", md: "5" }
                        },
                        [
                          _c("h1", { staticClass: "h1 font-italic" }, [
                            _vm._v(" We help you identify ")
                          ]),
                          _c(
                            "h1",
                            { staticClass: "orange-line font-italic " },
                            [_vm._v(" Realistic Export Opportunities ")]
                          ),
                          _vm.is_compatible_browser()
                            ? _c("div", { staticClass: "login" }, [
                                _c("div", { staticClass: "login-screen" }, [
                                  _c("form", [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "login-form",
                                        on: {
                                          keyup: function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            return _vm.start_login_process_local.apply(
                                              null,
                                              arguments
                                            )
                                          }
                                        }
                                      },
                                      [
                                        !_vm.login_started
                                          ? _c(
                                              "div",
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "control-group"
                                                  },
                                                  [
                                                    _c("label", {
                                                      attrs: { for: "username" }
                                                    }),
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value: _vm.username,
                                                          expression: "username"
                                                        }
                                                      ],
                                                      staticClass:
                                                        "login-field poppins mt-4",
                                                      attrs: {
                                                        id: "username",
                                                        placeholder: "username",
                                                        type: "text"
                                                      },
                                                      domProps: {
                                                        value: _vm.username
                                                      },
                                                      on: {
                                                        input: function(
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          ) {
                                                            return
                                                          }
                                                          _vm.username =
                                                            $event.target.value
                                                        }
                                                      }
                                                    }),
                                                    _c("label", {
                                                      staticClass:
                                                        "login-field-icon fui-user",
                                                      attrs: {
                                                        for: "login-name"
                                                      }
                                                    })
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "control-group"
                                                  },
                                                  [
                                                    _c("label", {
                                                      attrs: { for: "password" }
                                                    }),
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value: _vm.password,
                                                          expression: "password"
                                                        }
                                                      ],
                                                      staticClass:
                                                        "login-field",
                                                      attrs: {
                                                        id: "password",
                                                        autocomplete: "on",
                                                        placeholder: "password",
                                                        type: "password"
                                                      },
                                                      domProps: {
                                                        value: _vm.password
                                                      },
                                                      on: {
                                                        input: function(
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          ) {
                                                            return
                                                          }
                                                          _vm.password =
                                                            $event.target.value
                                                        }
                                                      }
                                                    }),
                                                    _c("label", {
                                                      staticClass:
                                                        "login-field-icon fui-lock",
                                                      attrs: {
                                                        for: "login-pass"
                                                      }
                                                    })
                                                  ]
                                                ),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    staticClass:
                                                      "btn btn-large btn-block",
                                                    attrs: {
                                                      id: "login",
                                                      color: "#f59f26"
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.start_login_process_local
                                                    }
                                                  },
                                                  [_vm._v("LOG IN ")]
                                                ),
                                                _c("v-checkbox", {
                                                  staticClass:
                                                    "shrink mt-1 mb-0 checksize ml-2 text-right poppins top",
                                                  attrs: {
                                                    label:
                                                      "Clear Cache on Login"
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.clear_casch_selected,
                                                    callback: function($$v) {
                                                      _vm.clear_casch_selected = $$v
                                                    },
                                                    expression:
                                                      "clear_casch_selected"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _c(
                                              "div",
                                              {
                                                staticClass: "trade-background"
                                              },
                                              [
                                                _c("v-progress-linear", {
                                                  attrs: {
                                                    indeterminate: "",
                                                    color: "rgb(245, 159, 38)"
                                                  }
                                                }),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "mt-12 mb-12 text-h5"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " Logging in please be patient.. "
                                                    ),
                                                    _c("v-progress-circular", {
                                                      attrs: {
                                                        width: 3,
                                                        color:
                                                          "rgb(245, 159, 38)",
                                                        indeterminate: ""
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _c("v-progress-linear", {
                                                  attrs: {
                                                    indeterminate: "",
                                                    color: "rgb(245, 159, 38)"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                        false
                                          ? [
                                              _c(
                                                "v-snackbar",
                                                {
                                                  attrs: {
                                                    timeout: 4000,
                                                    centered: "",
                                                    color: "red lighten-1",
                                                    shaped: ""
                                                  },
                                                  model: {
                                                    value: _vm.login_error,
                                                    callback: function($$v) {
                                                      _vm.login_error = $$v
                                                    },
                                                    expression: "login_error"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "h3",
                                                    {
                                                      staticClass:
                                                        "text-center pa-2 black--text lighten-2"
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Incorrect username or password. "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          : _vm._e()
                                      ],
                                      2
                                    )
                                  ])
                                ])
                              ])
                            : _c(
                                "div",
                                { staticClass: "mr-12 mt-5" },
                                [_c("browser_compat_box")],
                                1
                              )
                        ]
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", lg: "5", md: "7" } },
                        [
                          _vm.article !== "error"
                            ? [
                                _vm.article.Title
                                  ? [
                                      _vm.article.Title
                                        ? _c(
                                            "h1",
                                            {
                                              staticClass:
                                                "orange-line font-italics"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.article.Title) +
                                                  " "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "p",
                                        {
                                          staticClass: "orange-line font-italic"
                                        },
                                        [_vm._v(_vm._s(_vm.article.Headline))]
                                      ),
                                      _c("p", { staticClass: "top-line" }, [
                                        _vm._v(
                                          " " + _vm._s(_vm.article.p1) + " "
                                        )
                                      ]),
                                      _c("p", { staticClass: "top-line" }, [
                                        _vm._v(
                                          " " + _vm._s(_vm.article.p2) + " "
                                        )
                                      ]),
                                      _c("p", { staticClass: "top-line" }, [
                                        _vm._v(_vm._s(_vm.article.p3))
                                      ]),
                                      _c(
                                        "a",
                                        {
                                          staticClass: "white--text",
                                          attrs: {
                                            href: _vm.article["readMoreLink"],
                                            target: "_blank"
                                          }
                                        },
                                        [_vm._v("Read more")]
                                      )
                                    ]
                                  : _vm._e()
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("applcation_selector", {
            attrs: {
              select_application_open:
                _vm.$store.state.app.application_selector_open
            },
            on: {
              "update:select_application_open": function($event) {
                return _vm.$set(
                  _vm.$store.state.app,
                  "application_selector_open",
                  $event
                )
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }